import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Announcing D3blackbox and useD3",
  "description": "",
  "date": "2018-11-06T08:00:00.000Z",
  "published": "2018-11-06T08:00:00.000Z",
  "image": ""
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* wp:html */
    }
    <p><em parentName="p">{`Wrap any code you find in a React component and meet your deadline 🤫`}</em></p>
    <p>{`Ever find some good looking code online that you really want to use, but it just isn't quite React?`}</p>
    <p>{`This happens a lot with data visualization. You find a great example, you have no idea how it works, you just wanna see how it looks in your React app.`}</p>
    <p>{`Rewriting it all in React for a quick test is out of the question. You're not even sure how it's doing that thing you love so much.`}</p>
    <p>{`Enter `}<a parentName="p" {...{
        "href": "https://d3blackbox.com"
      }}><inlineCode parentName="a">{`D3blackbox`}</inlineCode></a>{`.`}</p>
    <p>{`Turn your code into a function, pass it into `}<inlineCode parentName="p">{`D3blackbox()`}</inlineCode>{`, voila: Self-contained React component.`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/km3l21y60r",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`👉 `}<a parentName="p" {...{
        "href": "https://d3blackbox.com"
      }}>{`d3blackbox.com`}</a></p>
    <p>{`👉 `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/d3blackbox"
      }}>{`github.com/Swizec/d3blackbox`}</a></p>
    <h2 {...{
      "id": "1-install-d3blackbox-and-d3"
    }}>{`1. Install `}<inlineCode parentName="h2">{`d3blackbox`}</inlineCode>{` and `}<inlineCode parentName="h2">{`d3`}</inlineCode></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`$ npm install d3blackbox d3
`}</code></pre>
    <h2 {...{
      "id": "2-wrap-d3-code-in-d3blackbox"
    }}>{`2. Wrap D3 code in `}<inlineCode parentName="h2">{`D3blackbox`}</inlineCode></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React from "react";
import D3blackbox from "d3blackbox";
import * as d3 from "d3";

const Barchart = D3blackbox(function (anchor, props) {
  const svg = d3.select(anchor.current);

  // the rest of your D3 code
});

export default Barchart;
`}</code></pre>
    <p><inlineCode parentName="p">{`D3blackbox`}</inlineCode>{` is a higher order component that takes a render function.`}</p>
    <p>{`It renders an anchor element and gives your renderer a React ref and all the props. You take over that anchor element with D3, or any other library, and do what you want.`}</p>
    <p><inlineCode parentName="p">{`D3blackbox`}</inlineCode>{` makes sure to call your renderer on mount and every component update. You're never going to see a stale render, I promise.`}</p>
    <h2 {...{
      "id": "3-render-your-component"
    }}>{`3. Render your component`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<barchart x={10} y={10} width={300} height={200}></barchart>
`}</code></pre>
    <p>{`Once you've created a D3blackbox'd React component, render as usual. If you want to change the anchor element, use the `}<inlineCode parentName="p">{`component`}</inlineCode>{` prop.`}</p>
    <p>{`You now have a reusable declarative component. 👌`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/km3l21y60r",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`You can render anything you want. No matter how complex the code you've found, D3blackbox can take it all.`}</p>
    <p>{`Here's an example of an interactive donut chart with tooltips. Didn't even need to read the D3 code. 😇`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/9jm82v2lry",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <h2 {...{
      "id": "what-about-hooks"
    }}>{`What about hooks?`}</h2>
    <p>{`Yep, there's a hook version too. `}<inlineCode parentName="p">{`useD3`}</inlineCode></p>
    <p>{`Same principle: anchor element, call D3 code on every update. Except with hooks there's less code you have to write.`}</p>
    <p>{`Works like this 👇`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { useD3 } from "d3blackbox";

function renderSomeD3(anchor) {
    d3.select(anchor);

    // ...
}

const MyD3Component = ({ x, y }) => {
    const refAnchor = useD3(anchor => renderSomeD3(anchor));

    return <g ref={refAnchor} transform="{\`translate(\${x}," \${y})\`}="">;
};
</g>
`}</code></pre>
    <p>{`You call `}<inlineCode parentName="p">{`useD3`}</inlineCode>{`, give it a render function, and receive a React ref. Your render function should take care of all the rendering, and the component where you call the hook needs to render the anchor element.`}</p>
    <p>{`I recommend always adding a `}<inlineCode parentName="p">{`transform`}</inlineCode>{` so you can position your components in an SVG.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`useD3`}</inlineCode>{` hook takes care of setting up a `}<inlineCode parentName="p">{`useRef`}</inlineCode>{` and a `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` hook so you don't have to worry about that.`}</p>
    <p>{`Meet your deadline in 2 minutes :)`}</p>
    <h2 {...{
      "id": "what-about-other-code"
    }}>{`What about other code?`}</h2>
    <p>{`You got it! There's nothing D3-specific in `}<inlineCode parentName="p">{`D3blackbox`}</inlineCode>{` and `}<inlineCode parentName="p">{`useD3`}</inlineCode>{` at all.`}</p>
    <p>{`They're generic wrappers for 3rd party code that wants to take over rendering inside a DOM element. React controls the wrapper, 3rd party render function controls the children.`}</p>
    <p>{`You can even use this to wrap Vue components in React components. Or jQuery plugins. Or Three.js code. Anything your heart desires.`}</p>
    <h2 {...{
      "id": "this-feels-dirty"
    }}>{`This feels dirty`}</h2>
    <p>{`It does. It is.`}</p>
    <p>{`You should use D3blackbox for quick prototypes, using example code that you don't want to rewrite, or small components where the performance hit doesn't matter.`}</p>
    <p>{`Using D3blackbox you are throwing away and redrawing the entire DOM subtree of your anchor element. The more such components you have and the bigger they are, the more performance will suffer.`}</p>
    <p>{`This can lead to redraws measured in `}<em parentName="p">{`seconds`}</em>{` on large dashboards. When that happens, I recommend `}<a parentName="p" {...{
        "href": "https://gumroad.com/l/pMtnZ"
      }}>{`React+D3`}</a>{` where I teach a more scalable approach with deep understanding of what's going on.`}</p>
    <p>{`But for quick and dirty, this is perfect. 👌`}</p>
    <h2 {...{
      "id": "with-love-️"
    }}>{`With love ❤️`}</h2>
    <p>{`Built with love this weekend and open sourced last night.`}</p>
    <p>{`I've been teaching this method in talks, workshops, books, and courses for 3 years now. Every time, I said: `}<em parentName="p">{`"Hm, I should open source this. Why am I asking you to copy paste?”`}</em></p>
    <p>{`Now I finally did. Enjoy`}</p>
    {
      /* /wp:html */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      